import $api, { API_URL } from "../../http";

export default class UserProfileService{
  static async update(data){
    return $api.patch(`${API_URL}/auth/profile`, data)
  }
  static async addNotificationMethod(data){
    return $api.post(`${API_URL}/notification-method/notification`, data)
  }

  static async deleteNotificationMethod(notificationMethodId){
    return $api.delete(`${API_URL}/notification-method/notification/${notificationMethodId}`)
  }
}
