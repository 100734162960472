import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/controllers/auth';

import './CreateAccountForm.scss';
import * as companyController from "../../api/controllers/company-controller";
import { useAuth } from "../../contexts/auth";

export default function CreateAccountForm() {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const formData = useRef({ email: '', password: '' });
  const { companyId = '' } = useParams();

  useEffect(() => {
    (async function () {
      setLoading(true);
      const result = await companyController.getAll();
      if (result.isOk) {
        setCompany(result.data);
        if (companyId){
          const foundCompany = result?.data.find(({id}) => Number(companyId) === Number(id))
          if (foundCompany){
            setSelectedCompany(foundCompany.name)
          }
        }
      }

      setLoading(false);
    })();
  }, []);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password, selectedCompanyId = companyId } = formData.current;
    setLoading(true);
    const result = await createAccount({ email, password, companyId: Number(selectedCompanyId) });
    setLoading(false);

    if (result.isOk) {
      await signIn(email, password)
      navigate('/home');
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate, companyId]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        { companyId ? <Item>{ selectedCompany }</Item> :
          <Item
            dataField={'selectedCompanyId'}
            editorType={'dxSelectBox'}
            editorOptions={ {
              ...companyEditorOptions,
              dataSource: company,
              displayExpr: 'name',
              valueExpr: 'id',
              defaultValue: companyId
            } }
          >
            <RequiredRule message="Компания обязательное поле" />
            <Label visible={false} />
          </Item> }
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email обязательное поле" />
          <EmailRule message="Email ненормальный" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Пароль обязательное поле" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Пароль обязательное поле" />
          <CustomRule
            message={'Пароли не совпадают'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Присоединиться'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Уже зарегистрированы? <Link to={'/login'}>Войти</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const companyEditorOptions = { stylingMode: 'filled', placeholder: 'Компания', noDataText: 'Данные не загружены', searchEnabled: true };
const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Пароль', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Подтверждение пароля', mode: 'password' };
