import Button from "devextreme-react/button";


// const getGridCellData = (gridData) => gridData.data[gridData.column.caption.toLowerCase()];

export const getGiveButtonCell = (createPaymentRequest, user) => (cellData= {}) => {

  const { data: { id, gifts = [] } = {} } = cellData
  // const gridCellData = getGridCellData(cellData);
  const onGiveClick = () => createPaymentRequest([id])

  const gift = gifts.find(({ userId })=> user.id === userId)

  const giveElement = gift?.amount ?
      <>
        Я подарил {gift.amount} руб.
      </>
:
  <Button type={'success'} text={'Подарить'} onClick={onGiveClick}/>

  return (
    giveElement
  )
}
