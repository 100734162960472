export class ControllerHelper {
    static prepareReturn(data){
        return {
            isOk: true,
            data: data
        };
    }
    static errCatcher(e){
        // if (e.response?.status === 401)
        //     setTimeout(logOut, 2000);

        return {
            isOk: false,
            message: (e.response?.data?.message) ?
                e.response?.data?.message :
                e.response?.statusText
        }
    }
}
