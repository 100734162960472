import UserProfileService from "../services/user-profile.service";

export async function updateProfile(data) {
    return await UserProfileService.update(data)
}

export async function addNotificationMethod(data) {
    return await UserProfileService.addNotificationMethod(data)
}

export async function deleteNotificationMethod(data) {
    return await UserProfileService.deleteNotificationMethod(data)
}
