import CustomStore from "devextreme/data/custom_store";
import { UniversalController } from "./controllers/universal-controller";
import notify from "devextreme/ui/notify";

export default function getUniversalStore({ methods, endpoint }) {

  const defaultMethods = {
    load: () => {
      return UniversalController.get({ endpoint })
        .then(res=>{
          if (res.isOk)
          {
            return res.data;
          }else{
            notify(res.message, 'error', 2000);
            return [];
          }
        }).catch(()=> {notify('Какая-то фигня', 'error', 2000)});
    },
    insert: (values) => {
      //console.log('Insert ', values.order_date?.toString());
      return UniversalController.add({ data: values, endpoint })
        .then(result=>{
          if (result.isOk) {
            notify(`Запись добавлена`, 'success', 2000)
          } else {
            throw new Error(result.message)
          }
        })
        .catch(e=>{
          console.log(e);
          throw new Error(e.message)
        })
    },
    update:(key, values)=>{
      //console.log('update ', values.order_date?.toString());
      return UniversalController.update({ id: key, data: values, endpoint })
        .then(result=>{
          if (result.isOk) {
            notify(`Запись обновлена`, 'success', 2000)
          } else {
            throw new Error(result.message)
          }
        })
        .catch(e=>{
          console.log(e);
          throw new Error(e.message)
        })
    },
    remove:(key)=>{
      return UniversalController.delete({ id: key, endpoint })
        .then(result=>{
          if (result.isOk) {
            notify(`Запись удалена`, 'success', 2000)
          } else {
            throw new Error(result.message)
          }
        })
        .catch(e=>{
          console.log(e);
          throw new Error(e.message)
        })
    }
  }

  const options = methods.reduce((result, item) => ({...result, [item]:defaultMethods[item]}),
    {  key: 'id', loadMode: 'raw' }) // omit in the DataGrid, TreeList, PivotGrid, and Scheduler

  return new CustomStore(options)
}
