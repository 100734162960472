import CompanyService from "../services/company-service";

export async function getAll() {
  try{
    const res = await CompanyService.getAll()
    return {
      isOk: true,
      data: res.data
    }
  }catch (e) {
    return {
      isOK: false,
      message: e.response?.data?.message || e.response?.statusText || JSON.stringify(e || {})
    }
  }
}

export async function get(id) {
  try{
    const res = await CompanyService.get(id)
    return {
      isOk: true,
      data: res.data
    }
  }catch (e) {
    return {
      isOk: false,
      message: e.response?.data?.message || e.response?.statusText || JSON.stringify(e || {})
    }
  }
}


