import { UniversalDataService } from "../services/universal-data-service";
import { ControllerHelper } from "../controller-helper";

export class UniversalController {
  static async get({ endpoint, searchParams }) {
    try {
      const response = await UniversalDataService.get({ endpoint, searchParams });
      return ControllerHelper.prepareReturn(response.data);
    } catch (e) {
      return ControllerHelper.errCatcher(e)
    }
  }

  static async add({ endpoint, data }) {
    try {
      const response = await UniversalDataService.post({ endpoint, data});
      return ControllerHelper.prepareReturn(response.data);
    } catch (e) {
      return ControllerHelper.errCatcher(e)
    }
  }
  static async put({ data, endpoint }) {
    try {
      const response = await UniversalDataService.put({ endpoint, data });
      return ControllerHelper.prepareReturn(response.data);
    } catch (e) {
      return ControllerHelper.errCatcher(e)
    }
  }
  static async update({ id, data, endpoint }) {
    try {
      const response = await UniversalDataService.patch({ endpoint, id, data });
      return ControllerHelper.prepareReturn(response.data);
    } catch (e) {
      return ControllerHelper.errCatcher(e)
    }
  }

  static async delete({ id, endpoint }) {
    try {
      const response = await UniversalDataService.delete({ id, endpoint });
      return ControllerHelper.prepareReturn(response.data);
    } catch (e) {
      return ControllerHelper.errCatcher(e)
    }
  }
}
