export const navigation = [
  {
    text: 'События',
    path: '/home',
    icon: 'gift'
  },
  // {
  //   text: 'События',
  //   path: '/events',
  //   icon: 'gift'
  // },
  {
    text: 'Профиль',
    path: '/profile',
    icon: 'user'
  },
  {
    text: 'Admin',
    path: '/admin',
    icon: 'card',
    role: ['admin']
  },
  // {
  //   text: 'Examples',
  //   icon: 'folder',
  //   items: [
  //
  //     {
  //       text: 'Tasks',
  //       path: '/tasks'
  //     }
  //   ]
  // }
  ];
