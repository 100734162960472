import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { logOut, getUser, signIn as sendSignInRequest } from '../api/controllers/auth';
import * as authController from "../api/controllers/auth";

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const setUserData = (user) => {
    setUser(user)
  }

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const fastSignIn = useCallback(async (credentials) => {
    await authController
      .fastAuth(credentials)
      .then(async (result) => {
        if (result.isOk) {
          const { accessToken, refreshToken } = result.data || {}
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          const resultUser = await getUser()
          if (resultUser.isOk) {
            setUser(resultUser.data);
          }else{
            throw new Error(result.message)
          }
        }else{
          throw new Error(result.message)
        }
      })
  },[])

  const signIn = useCallback(async (credentials) => {
    const result = await sendSignInRequest(credentials);
    if (result.isOk) {
      const result = await getUser()
      if (result.isOk) {
        setUser(result.data);
      }else{
        throw new Error(result.message)
      }
    }

    return result;
  }, []);

  const signOut = useCallback(async () => {
    await logOut()
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, setUserData, signIn, fastSignIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
