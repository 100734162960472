import CompanyService from "../services/company-service";
import ListsService from "../services/lists-service";

export async function getAllNotificationMethods() {
  try{
    const res = await ListsService.getAllNotificationMethods()
    return {
      isOk: true,
      data: res.data
    }
  }catch (e) {
    return {
      isOK: false,
      message: e.response?.data?.message || e.response?.statusText || JSON.stringify(e || {})
    }
  }
}
