import React, { useEffect, useRef, useState } from 'react';
import './home.scss';
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  SearchPanel,
  Toolbar,
  Item,
  MasterDetail
} from "devextreme-react/data-grid";
import { loadMessages, locale } from "devextreme/localization";
import ruMessages from "devextreme/localization/messages/ru.json";
import getUniversalStore from "../../api/universal-store";
import { RequiredRule } from "devextreme-react/form";
import Button from "devextreme-react/button";
import { UniversalController } from "../../api/controllers/universal-controller";
import notify from "devextreme/ui/notify";
import { getGiveButtonCell } from "./GiveButtonCell";
import { useAuth } from "../../contexts/auth";
import DetailTemplate from "./EventDetailTemplate";


const url = 'https://yoomoney.ru/quickpay/confirm'
export default function Home() {
  const { user } = useAuth();
  const grid = useRef()
  const [eventsDataSource, setEventsDataSource] = useState([]);
  const endpoint = 'event';
  const path = 'youmoney/payment'

  useEffect(()=>{
    loadMessages(ruMessages);
    locale('ru');

    setEventsDataSource(getUniversalStore({ methods: ['load', 'insert', 'update', 'remove'], endpoint }))
  },[] );

  const rowPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.Speed > e.data.SpeedLimit) {
        e.cellElement.style.cssText = "color: white; background-color: red";
        // or
        e.rowElement.classList.add("my-class");
        // To override alternation color
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
    }
  }

  const onGiftAllClick = () => {
    const events = grid.current.instance.getDataSource()
      .items()
      .filter(event=>!event.gifts.some(({ userId, amount })=> user.id === userId && amount))
      .map(({id})=>id)

    if (events.length)
      return createPaymentRequest(events)

    notify('Все уже одарены, спасибо', 'success', 3000)
  }

  const onRefreshDataClick = () => {
    grid.current.instance.refresh()
  }

  const createPaymentRequest = (events) => {
    UniversalController
      .add({ endpoint: path, data: { events } })
      .then(({ isOk, data, message })=>{
        if (isOk){
          const params = Object.keys(data).map((key)=> `${key}=${encodeURIComponent(data[key])}` ).join('&')
          window.location.href = `${url}?${params}`
        }else{
          notify('Обновите страницу и попробуйте снова. \n' + message, 'error', 5000)
        }
      })
  }

  const onGiveCashClick = ({ eventId, userId }) => {
    UniversalController
      .add({ endpoint: 'event/deposit', data: { eventId, userId } })
      .then(({ isOk, message }) => {
        if (!isOk) notify(message, 'error', 3000)
      })
      .finally(() => {
        onRefreshDataClick()
      })
  }

  const isAdmin = user.role === 'admin'

  const detailTemplateRender = (props) => {
    return <DetailTemplate data={props.data} user={user} onGiveCashClick={onGiveCashClick}/>
  }

  const calculateTotalCellValue = ({ gifts = [] } = {}) =>
    gifts.reduce((sum, { amount = 0 } = {})=>(sum + amount),0) + ' ₽'

  return (
    <React.Fragment>
      <h2 className={'content-block'}>События</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <DataGrid
            ref={grid}
            height={'80vh'}
            dataSource={eventsDataSource}
            onRowPrepared={rowPrepared}
            keyExpr="id"
          >
            <Toolbar>
              <Item location="before">
                <Button
                  text={'Одарить всех'}
                  type={'success'}
                  onClick={onGiftAllClick}
                />
              </Item>
              {isAdmin && <Item name={'addRowButton'} location={'after'}></Item>}
              <Item location="after">
                <Button
                  icon={'refresh'}
                  type={'normal'}
                  onClick={onRefreshDataClick}
                />
              </Item>
              {/*<Item name="searchPanel" />*/}
            </Toolbar>
            <Column caption="Сделать подарок" width={130} cellRender={getGiveButtonCell(createPaymentRequest, user)}/>
            <Column dataField={'id'} caption="id" visible={false}/>
            <Column dataField={'name'} caption="Событие"  >
              <RequiredRule />
            </Column>
            <Column dataField={'date'} caption="Дата" dataType={'date'}  >
              <RequiredRule />
            </Column>
            <Column caption="Всего подарено" calculateCellValue={ calculateTotalCellValue } valueFormat="currency"/>

            {isAdmin && <Editing
              allowUpdating={true}
              allowAdding={true}
              // allowDeleting={true}
              texts={{
                saveRowChanges: "Сохранить",
                cancelRowChanges: "Отмена",
                confirmDeleteMessage:"Запись будет безвозвратно удалена, продолжить?"
              }}
              mode="cell" >
            </Editing>
            }


            <MasterDetail
              enabled={true}
              autoExpandAll={false}
              render={detailTemplateRender}
            />

            <SearchPanel visible={true}
                         width={240}
                         placeholder="Поиск..." />
            <Scrolling mode="virtual" />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
