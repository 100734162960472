import React, { useEffect } from "react";
import { UniversalController } from "../../api/controllers/universal-controller";
import notify from "devextreme/ui/notify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "devextreme-react/button";

export default function AdminPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  // const [isLoading, setIsLoading] = useState(false);
  const path = 'youmoney'

  useEffect(() => {
    const code = searchParams.get('code')
    const error = searchParams.get('error')
    const error_description = searchParams.get('error_description')

    if (!code) {
      if (error || error_description)
        notify(error + '\n' + error_description, 'error', 3000)
      return
    }

    // setIsLoading(true)
    UniversalController
      .add({ endpoint: path, data: { code } })
      .then(({ isOk, data, message })=>{
        if (isOk){
          notify('Успешно', 'success', 5000)
        }else{
          notify(message, 'error', 3000)
        }
      })
      .finally(()=> {
        navigate('/admin')
      })
  }, [navigate, searchParams]);

  const onConnectYooMoneyClick = () => {
    UniversalController.get({ endpoint: path })
      .then(({ isOk, data, message })=>{
        if (isOk){
          const params = Object.keys(data).map((key)=> `${key}=${encodeURIComponent(data[key])}` ).join('&')
          window.location.href = `https://yoomoney.ru/oauth/authorize?${params}`
        }else{
          notify(message, 'error', 3000)
        }
      })
      // .finally(()=>setIsLoading(false))
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Админка</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <Button text={'Connect YooMoney'} onClick={onConnectYooMoneyClick} />
        </div>
      </div>
    </React.Fragment>
  )
}
