import NotificationMethodComponent from "./notification-method.component";

export default {
  email: {
    component: NotificationMethodComponent,
    placeholder: 'Email'
  },
  sms: {
    component: NotificationMethodComponent,
    placeholder: 'Номер мобильного телефона'
  },
  telegram: {
    component: NotificationMethodComponent,
    placeholder: 'Ничего не надо',
    defaultValue: 'Telegram'
  },
  'phone-call': {
    component: NotificationMethodComponent,
    placeholder: 'Номер мобильного телефона'
  }
}

