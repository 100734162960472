import React, { useCallback, useEffect, useRef, useState } from 'react';
import './profile.scss';
import Form, {
  CustomRule,
  Item,
  Label,
  RequiredRule,
  StringLengthRule
} from 'devextreme-react/form';
import { getUser } from "../../api/controllers/auth";
import { getAllNotificationMethods } from "../../api/controllers/lists-controller";
import { updateProfile } from "../../api/controllers/profile-controller";
import notify from "devextreme/ui/notify";
import notificationMethodsSettings from "./notification-methods";
import Button from "devextreme-react/button";
import { Popup } from "devextreme-react";
import LoadIndicator from "devextreme-react/load-indicator";
import { UniversalController } from "../../api/controllers/universal-controller";
import { useAuth } from "../../contexts/auth";

export default function Profile() {
  const { user, setUserData } = useAuth()
  const form = useRef()
  const changePasswordForm = useRef()
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const changePasswordData = useRef({verificationCode: '', password: '', confirmedPassword: ''});
  const [notificationMethods, setNotificationMethods] = useState([]);
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const isOnlyProfile = !user?.name || !user?.bDay

  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible);
  };

  useEffect(() => {
    setLoading(true);
      getAllNotificationMethods()
      .then(result => {
        if (result.isOk) {
          setNotificationMethods(result.data)
          return getUserData()
        }
      })
      .then(result => {
        if (result.isOk) {
          updateUserProfile(result.data)
        }
      })
      .finally(() => setLoading(false))
  }, []);


  const getUserData = () => getUser()
  const updateUserProfile = (data) => setUserProfile(data)

  const onFieldDataChanged = (e) => {
    if (!form.current.instance.validate())
      return;

    const fieldsWithoutVerification = [ 'bDay', 'name' ]
    const updatedField = e.dataField;
    const newValue = e.value;

    setLoading(true);

    const sendUpdatedData = (data) => updateProfile(data)

    const { update = ()=> Promise.resolve({ }) } = [
      {
        condition: fieldsWithoutVerification.includes(updatedField),
        update: () => sendUpdatedData({ [updatedField]: newValue })
      }
    ].find(({condition}) => condition) || {}

    update()
      .then((response) => {
        setUserData(response.data)
        notify(`${ updatedField } обновлено`, 'success', 2000)
        return getUserData()
      })
      .then(result => {
        if (result.isOk) {
          updateUserProfile(result.data)
        }
      })
      .catch(e=>notify(e.message, 'error', 3000))
      .finally(()=>setLoading(false))
  }

  const onChangePasswordClick = () => {
    setLoading(true)
    UniversalController.put({ endpoint: 'auth/password', data: {} })
      .then((response)=>{
        if (response.isOk) {
          togglePopup()
        }else{
          notify(`Произошла ошибка ${response.message}`, 'error', 2000)
        }
      })
      .finally(()=>setLoading(false))
  }

  const sendNewPassword = () => {
    const validationResult = changePasswordForm.current.instance.validate()
    if (!validationResult.isValid)
      return;

    setLoading(true)
    UniversalController.put({ endpoint: 'auth/password',
      data: {
        verificationCode: changePasswordData.current.verificationCode,
        password: changePasswordData.current.password
      }
    })
      .then((response)=>{
        if (response.isOk) {
          togglePopup()
          notify(`Пароль успешно обновлен`, 'success', 2000)
        }else{
          notify(`При смене пароля произошла ошибка ${response.message}`, 'error', 2000)
        }
      })
      .catch(e=>{
        console.error('[sendNewPassword]', e)
      })
      .finally(()=>setLoading(false))
  }

  const confirmPassword = useCallback(
    ({ value }) => (value === changePasswordData.current.password),
    []
  );

  const popUpRenderContent = () => {

    return (
      <>
        <h4>Для подтверждения смены пароля мы отправили Вам код на {userProfile?.login}</h4>
        <Form
          ref={changePasswordForm}
          formData={changePasswordData.current}
          labelLocation={ 'top' }
          disabled={ loading }
        >
          <Item
            dataField={ 'verificationCode' }
            editorType={ 'dxTextBox' }
            editorOptions={ codeEditorOptions }
          >
            <RequiredRule message="Это обязательное поле"/>
            <Label text={'Код подтверждения'} visible={ true }/>
          </Item>
          <Item
            dataField={'password'}
            editorType={'dxTextBox'}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Пароль обязательное поле" />
            <StringLengthRule message={'Пароль не может быть таким коротким'} min={6}/>
            <Label text={'Новый пароль'} visible={true} />
          </Item>
          <Item
            dataField={'confirmedPassword'}
            editorType={'dxTextBox'}
            editorOptions={confirmedPasswordEditorOptions}
          >
            <RequiredRule message="Пароль обязательное поле" />
            <CustomRule
              message={'Пароли не совпадают'}
              validationCallback={confirmPassword}
            />
            <Label text={'Подтверждение пароля'} visible={true} />
          </Item>
        </Form>
        <div style={ { display: 'flex', flexDirection: 'row', gap: '10px', paddingTop: '20px', justifyContent: 'flex-end' }}>
          <Button type={'success'} onClick={sendNewPassword} style={{ minWidth:'150px' }}>
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : <span style={{paddingLeft:'10px', paddingRight: '10px'}}>Сменить пароль</span>
              }
            </span>
          </Button>
          <Button text={'Отмена'} type={'normal'} onClick={togglePopup}></Button>
        </div>
      </>
    )
  }

  const codeEditorOptions = { stylingMode: 'filled', placeholder: 'Код', mode: 'text' };
  const calendarEditorOption = { width: '100%', useMaskBehavior: true, displayFormat: 'dd.MM'}
  const passwordEditorOptions = { defaultValue: 'password', width: '100%', stylingMode: 'filled', mode: 'password' };
  const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Подтверждение пароля', mode: 'password' };

  return (
    <React.Fragment>
      <h2 className={ 'content-block' }>Профиль</h2>
      {isOnlyProfile ? <h3 className={ 'content-block' } style={{color:"orangered"}}>Остальные разделы будут доступны, когда Вы заполните имя и дату рождения, Затем обновите страницу</h3>: <></>}
      <h3 className={ 'content-block' }>Ваша организация { userProfile?.company?.name }</h3>
      <div className={ 'content-block dx-card responsive-paddings' }>
        <Form
          ref={form}
          formData={userProfile}
          labelLocation={ 'top' }
          disabled={ loading }
          colCountByScreen={colCountByScreen}
          onFieldDataChanged={onFieldDataChanged}
        >
          <Item
            dataField={ 'name' }
            editorType={ 'dxTextBox' }
            // editorOptions={{onValueChanged: onChangedName}}
            >
            <RequiredRule message="Имя обязательное поле, ну как же без него!?"/>

            <Label visible={true} text='Имя (Вы можете указать любое имя под которым Вас узнают)' />
          </Item>
          <Item
            dataField={ 'bDay' }
            editorType={ 'dxDateBox' }
            editorOptions={ calendarEditorOption }
          >
            <RequiredRule message="Это обязательное поле"/>
            <Label visible={true} text='Ваш день рождения (Важно точно указать только день и месяц, а год можно поставить любой)' />
          </Item>
          <Item
            dataField={ 'login' }
            editorType={ 'dxTextBox' }
            disabled={true}
            >
            <RequiredRule message="Это обязательное поле"/>
            <Label visible={true} text='Логин (Имя под которым Вы входите в приложение)' />
          </Item>
          <Item>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
              <Button text={'Сменить пароль'} onClick={onChangePasswordClick}/>
            </div>
            <Label visible={ true } text={'Пароль'}/>
          </Item>
        </Form>
      </div>
      <h3 className={ 'content-block' }>Методы связи с Вами, для отправки напоминаний</h3>
      {
        !isOnlyProfile && notificationMethods.map((nm, id) => {
          const { defaultValue, placeholder, component: NotificationComponent } = notificationMethodsSettings[nm.notificationServiceName]
          const userNotificationMethod = userProfile?.userNotificationMethods?.find(({notificationMethodId})=> nm.id === notificationMethodId)
          return <NotificationComponent key={id} getUserData={getUserData} defaultValue={defaultValue} updateUserProfile={updateUserProfile} placeholder={placeholder} userNotificationMethod={userNotificationMethod?.value} { ...nm }/>
        })
      }
      <Popup
        contentRender={popUpRenderContent}
        visible={isPopupVisible}
        hideOnOutsideClick={false}
        onHiding={togglePopup}
        showTitle={true}
        showCloseButton={true}
        title="Смена пароля"
        height={460}
        width={300}
      />
    </React.Fragment>
  );
}

function screenByWidth(width) {
  if (width < 768)  return 'xs';
  if (width < 992)  return 'sm';
  if (width < 1200) return 'md';
  return 'lg';
}

const percentByScreen = {
  xs: '100%',
  sm: '80%',
  md: '60%',
  lg: '50%'
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2
};
