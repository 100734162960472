import axios from 'axios';
export const SERVER_URL=process.env.NODE_ENV==='development'?'http://localhost:3001':"https://fr.ukk61.ru";
export const API_URL = `${SERVER_URL}/api`

const $api = axios.create({
  // withCredentials: true,
  baseURL: API_URL
})

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return config;
})

$api.interceptors.response.use((config) => {
  return config;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error._isRetry) {
    originalRequest._isRetry = true;
    try {
      // const response = await axios.get(`${API_URL}/refresh`, { withCredentials: true });
      const response = await axios.get(`${API_URL}/auth/refresh`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
        }
      });
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return $api.request(originalRequest);
    } catch (e) {

      // history.push("/login")
      console.log('Не авторизован');

      setTimeout(()=>{
        // console.log('window.location', window.location)
        // window.location.assign("/login");
      },1000);
    }
  }
  throw error;
})

export default $api;
