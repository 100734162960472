import $api from "../../http";

export class UniversalDataService {

  static async get({ endpoint, searchParams }) {
    return $api.get(`/${endpoint}`, { params: searchParams });
  }

  static async post({ endpoint, data }) {
    return $api.post(`/${endpoint}`, { ...data });
  }

  static async put({ endpoint, data }) {
    return $api.put(`/${endpoint}`, { ...data });
  }

  static async patch({ endpoint, id, data }) {
    return $api.patch(`/${endpoint}/${id}`, { ...data, id });
  }

  static async delete({ endpoint, id }) {
    return $api.delete(`/${endpoint}/${id}`);
  }
}
