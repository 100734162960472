import React, { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import './LoginForm.scss';
import Button from "devextreme-react/button";
import * as companyController from "../../api/controllers/company-controller";
import * as authController from "../../api/controllers/auth";
import ReCAPTCHA from "react-google-recaptcha";

const authMethodEnum = {
  verificationCode: 'verificationCode',
  loginPassword: 'loginPassword'
}

const stageEnum = {
  stageLogin: 'stageLogin',
  stageVerification: 'stageVerification'
}

const emailRegex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
const phoneRegex =new RegExp(/^\+7\d{10}$/)
export default function LoginForm() {
  const captchaRef = useRef(null)
  const { signIn, fastSignIn } = useAuth();
  const form = useRef()
  const [loading, setLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState(authMethodEnum.verificationCode)
  const [stage, setStage] = useState(stageEnum.stageLogin)
  const formData = useRef({ email: '', password: '' });
  const [company, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyId, setCompanyId] = useState();
  const { paramCompanyId = '' } = useParams();

  const onSendLogin = useCallback(async (e) => {
    const validationResult = form.current.instance.validate()
    if (!validationResult.isValid)
      return;

    setLoading(true);
    const token = await captchaRef.current?.executeAsync().catch(console.error);
    captchaRef.current.reset()
    const { login, verificationCode, selectedCompanyId } = formData.current;
    const email = emailRegex.test(login) ? login : undefined
    const phoneNumber = phoneRegex.test(login) ? login : undefined

    if (authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageLogin){
      // отпраляем email или phoneNumber для запрса кода
      await authController.fastAuth({ login: email || phoneNumber, token })
        .then(async (result) => {
          if (result.isOk) {
            const companyId = paramCompanyId || result.data.companyId
            setCompanyId(companyId)
            setStage(stageEnum.stageVerification)

            await companyController.getAll()
              .then(result => {
                if (result.isOk) {
                  setCompany(result.data);

                  if (companyId) {
                    const foundCompany = result?.data.find(({ id }) => Number(companyId) === Number(id))
                    if (foundCompany) {
                      setSelectedCompany(foundCompany.name)
                    }
                  }
                }
              })
              .catch(console.error)
          } else {
            notify(result.message, 'error', 2000)
          }
        })
        .catch(console.error)
    }else if (authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageVerification){
      await fastSignIn({ login: email || phoneNumber, twoFaCode: verificationCode, companyId: selectedCompanyId || paramCompanyId, token })
        .catch(e=> {
          console.error('e', e)
          notify(e.message, 'error', 2000)
        })

    } else if (authMethod === authMethodEnum.loginPassword){
      // отправляем логин пароль
      await signIn({ login: email || phoneNumber, password: verificationCode, token})
        .catch(console.error)
    }
    setLoading(false);
  }, [signIn, authMethod, stage, paramCompanyId, fastSignIn]);

  const onChangeAuthMethod = () => {
    if (stage === stageEnum.stageVerification)
      return setStage(stageEnum.stageLogin)

    const method = authMethod === authMethodEnum.verificationCode ? authMethodEnum.loginPassword : authMethodEnum.verificationCode
    setAuthMethod(method)
  }

  const loginValidation = useCallback((evt = {}, s) => {

    const { value } = evt
    return emailRegex.test(value.toString()) || phoneRegex.test(value)
  }, [])

  const onLoginChange = () => {
    form.current.instance.validate()
  }

  const onLoginFieldInitialized = (e) => {
    setTimeout(function () {
      e.component.focus();
    }, 100);
  }

  const companyEditorOptions = { stylingMode: 'filled', placeholder: 'Компания', noDataText: 'Данные не загружены', searchEnabled: true };
  const loginEditorOptions = { stylingMode: 'filled', placeholder: 'Email', onChange: onLoginChange, onInitialized: onLoginFieldInitialized };
  const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Пароль', mode: 'password' };
  const codeEditorOptions = { stylingMode: 'filled', placeholder: 'Код', mode: 'text' };
  const buttonOptions = {
    width: '100%',
    icon: authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageLogin? '' : 'back',
    type: authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageLogin ? 'default' : 'normal',
    text: authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageLogin ? 'Вход по паролю' : 'Отмена',
    onClick: onChangeAuthMethod
  }

  return (
    <div>
      <h3>{ authMethod === authMethodEnum.verificationCode ?  stage === stageEnum.stageLogin ? 'Вход / Регистрация' : 'Введите проверочный код' : 'Вход с паролем' }</h3>
      <form className={ 'login-form' }>
        <Form ref={form} formData={ formData.current } disabled={ loading }>
          { stage === stageEnum.stageLogin ? <Item visible={false}/>:
            companyId ? <Item>{ selectedCompany }</Item> :
            <Item
              dataField={'selectedCompanyId'}
              editorType={'dxSelectBox'}
              editorOptions={ {
                ...companyEditorOptions,
                dataSource: company,
                displayExpr: 'name',
                valueExpr: 'id',
                defaultValue: companyId
              } }
            >
              <RequiredRule message="Компания обязательное поле" />
              <Label visible={false} />
            </Item> }
          <Item
            dataField={ 'login' }
            editorType={ 'dxTextBox' }
            editorOptions={ loginEditorOptions }
            visible={ stage === stageEnum.stageLogin }
          >
            <RequiredRule message="Это обязательное поле"/>
            <CustomRule message='Это должен быть Email' type='custom' validationCallback={loginValidation} />
            <Label visible={ false }/>
          </Item>
          <Item
            dataField={ 'verificationCode' }
            editorType={ 'dxTextBox' }
            editorOptions={ stage === stageEnum.stageLogin ? passwordEditorOptions : codeEditorOptions }
            visible={authMethod !== authMethodEnum.verificationCode || stage === stageEnum.stageVerification}
          >
            <RequiredRule message="Это обязательное поле"/>
            <Label visible={ false }/>
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={ '100%' }
              type={ 'default' }
              onClick={ onSendLogin }
            >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={ '24px' } height={ '24px' } visible={ true }/>
                  : authMethod === authMethodEnum.verificationCode && stage === stageEnum.stageLogin ? 'Далее' : 'Вход'
              }
            </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
        <ReCAPTCHA
          sitekey={ process.env.REACT_APP_SITE_KEY }
          ref={captchaRef}
          size={'invisible'}
          theme={'dark'}
        />
      </form>
      <div>
        { stage === stageEnum.stageLogin && authMethod === authMethodEnum.verificationCode ? 'Другой способ входа:' : <hr/>}
        <Button { ...buttonOptions } />
      </div>
    </div>
  );
}


