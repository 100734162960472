import React, { useEffect, useState } from 'react';
import { Popup, TextBox } from "devextreme-react";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import * as ProfileController from "../../api/controllers/profile-controller";

function NotificationMethodComponent(props) {
  const [validationStatus, setValidationStatus] = useState("valid");
  const [validationErrors, setValidationErrors] = useState([]);
  const [methodValue, setMethodValue] = useState();
  const [codeValue, setCodeValue] = useState('');
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [verificationOptions, setVerificationOptions] = useState({});

  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible);
  };

  useEffect(() => {
    setMethodValue(props.userNotificationMethod || props.defaultValue)
  }, [props.userNotificationMethod]);

  const onClickMethod = () => {
    if (!methodValue || methodValue.toString() === '') {
      setValidationStatus('invalid')
      setValidationErrors([{ message: 'Пустое значение недопустимо' }])
      return notify('Пустое значение недопустимо', 'error', 3000)
    }

    if (props.userNotificationMethod){
      return ProfileController.deleteNotificationMethod(props.id)
        .then(response=>props.updateUserProfile(response.data))
        .catch(e=>{
          return notify( e.response?.data || e.message, 'error', 3000)
        })
    }

    return ProfileController.addNotificationMethod({ notificationMethodId: props.id, value: methodValue })
      .then(response=>{
        setVerificationOptions(response.data)
        setPopupVisibility(true)
      })
      .catch(e=>{
        return notify(e.response.data || e.message, 'error', 3000)
      })
  }

  const sendVerification = () => {

    if (codeValue === '') {
      return props.getUserData()
        .then(response=>props.updateUserProfile(response.data))
        .catch(e=>{
          return notify(e.response.data || e.message, 'error', 3000)
        })
        .finally(togglePopup)
    }

    return ProfileController.addNotificationMethod({ notificationMethodId: props.id, value: methodValue, code: codeValue })
      .then(response=>{
        props.updateUserProfile(response.data)
      })
      .catch(e=>{
        return notify(e.response.data || e.message, 'error', 3000)
      })
      .finally(togglePopup)
  }

  const popUpRenderContent = () => {
    const { verificationType, link } = verificationOptions
    const getContent = {
      link: ()=>{
        return (
          <>
            <p>Для привязки перейдите по ссылке, вполняйте инструкции, затем нажмите в Телеграме "старт"</p>
            <a href={ link } target='_blank' rel='noopener noreferrer'>Привязать</a>
          </>
        )
      },
      code: ()=>{
        return (
          <>
            <p>Мы отправили вам проверочный код введите его</p>
            <TextBox value={codeValue} onValueChange={(e)=>setCodeValue(e)} />
          </>
        )
      }
    }[verificationType] || (() => null)

    return (
      <>
      { getContent() }
        <div style={ { display: 'flex', flexDirection: 'row', gap: '10px', paddingTop: '20px', justifyContent: 'flex-end' }}>
          <Button text={'Ок'} type={'success'} onClick={sendVerification}></Button>
          <Button text={'Отмена'} type={'normal'} onClick={togglePopup}></Button>
        </div>
      </>
    )
  }

  return (
    <div className={ 'content-block dx-card responsive-paddings' }>
      <div style={{paddingBottom: '10px'}}>{ props.name }</div>
      <div style={{ width: "100%", align: 'start', display: 'flex', direction: 'row', alignItems: 'center',  gap: '10px'}}>
        <div>
          <img src={ `./images/${props.notificationServiceName}.png`} width={50} alt={props.notificationServiceName} style={{filter: `grayscale(${props.userNotificationMethod ? "0" : "1"})`}}/>
        </div>
        <Popup
          contentRender={popUpRenderContent}
          visible={isPopupVisible}
          hideOnOutsideClick={false}
          onHiding={togglePopup}
          showTitle={true}
          showCloseButton={true}
          title="Проверка"
          height={225}
          width={300}
        />
        <TextBox
          validationStatus={validationStatus}
          validationErrors={validationErrors}
          placeholder={props.placeholder}
          value={methodValue}
          disabled={!!props.userNotificationMethod}
          onChange={(e)=>{
            setValidationStatus('valid')
          }}
          onValueChange={(e)=> setMethodValue(e) }
        />
        <div>
          <Button type={ props.userNotificationMethod ? '' : 'default' } text={ props.userNotificationMethod ? 'Отключить' : 'Подключить'} onClick={onClickMethod}/>
        </div>
      </div>
    </div>
  );
}


export default NotificationMethodComponent;
