import $api, {API_URL} from '../../http';

export default class CompanyService{
  static async getAll(){
    return $api.get(`${API_URL}/company`)
  }
  static async get(id){
    return $api.get(`${API_URL}/company/${id}`)
  }
}
