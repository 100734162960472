import Button from "devextreme-react/button";

export const getGiveCashButtonCell = (user, onGiveCashClick, event) => (cellData= {}) => {

  const { data: { amount, userId } = {} } = cellData

  const onGiveClick = () => {
    onGiveCashClick({ eventId: event.id, userId  })
  }

  const giveElement = amount ?
    <>
      {amount} ₽.
    </>
    :
      user.role === 'admin' ?
        <Button type={ 'success' } text={ 'Подарить наличку' } onClick={ onGiveClick }/>
        : <></>

  return (
    giveElement
  )
}
