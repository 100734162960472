import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, CreateAccountForm } from './components';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login/:companyId?'
        element={
          <SingleCard
            title="Фандрайзер"
            description="Коллективный сбор средств."
          >
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/create-account/:companyId?'
        element={
          <SingleCard title="Зарегистрироваться">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route
        path='/reset-password'
        element={
          <SingleCard
            title="Сброс пароля"
            description="Пожалуйста введите адрес электронной почты указанный при регистрации, мы отправим на него ссылку для восстановления пароля."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
