import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { getGiveCashButtonCell } from "./GiveCashButon";

const getGifts = (key, data) =>
  new DataSource({
    store: new ArrayStore({
      data,
      key: 'userId',
    }),
    // filter: ['EmployeeID', '=', key],
  });

const DetailTemplate = (props) => {
  const dataSource = getGifts(props.data.key, props.data.gifts);

  return (
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column caption="Пльзователь" dataField="name" />
        <Column caption="Сумма" dataField="amount" cellRender={getGiveCashButtonCell(props.user, props.onGiveCashClick, props.data)} />
        <Column
          caption="Дата"
          dataField="createdAt"
          dataType="date"
        />
      </DataGrid>
  );

}
export default DetailTemplate
