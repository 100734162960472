import authService from "../services/authService.js";

export async function signIn(credentials) {
  try {
    // Send request
    const response = await authService.login(credentials)
    const { accessToken, refreshToken } = response.data
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}
export async function fastAuth(emailOrPhoneNumber) {
  try {
    // Send request
    const response = await authService.fastAuth(emailOrPhoneNumber)

    return {
      isOk: true,
      data: response.data
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}
export async function logOut(email, password) {
  try {
    // Send request
    await authService.logout()

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    return {
      isOk: true,
    };
  }
  catch (e){
    return {
      isOk: false,
      message: e.message
    };
  }
}

export async function getUser() {
  try {
    // Send request
    const response = await authService.getProfile()

    return {
      isOk: true,
      data: response.data
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(data) {
  try {
    // Send request
    const response = await authService.signUp(data)

    const { accessToken, refreshToken } = response.data
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

// export async function changePassword(email, recoveryCode) {
//   try {
//     // Send request
//     // console.log(email, recoveryCode);
//
//     return {
//       isOk: true
//     };
//   }
//   catch {
//     return {
//       isOk: false,
//       message: "Failed to change password"
//     }
//   }
// }

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
