import $api, {API_URL} from '../../http';
import axiosA from 'axios';

export default class AuthService{

    static async getProfile(){
        return $api.get('/auth/profile')
    }
    static async login(credentials){
        return $api.post('/auth/login',{...credentials})
    }
    static async fastAuth(emailOrPhoneNumber){
        return $api.post('/auth/fastauth',{...emailOrPhoneNumber})
    }

    static async signUp(data){
        return $api.post('/auth/signup',{...data})
    }

    static async logout(){
        return $api.get('/auth/logout')
    }

    static async checkAuth(){
        try {
            // return await axiosA.get(`${API_URL}/refresh`, {withCredentials: true})
            return await axiosA.get(`${API_URL}/auth/refresh`)
        } catch (e) {
            throw Error(e.response?.data?.message);
        }
    }

    static async resetPassword(password, recoveryCode){
        return $api.post('/auth/resetPasswordChange',{password, recoveryCode})
    }

    static async resetPasswordRequest(email){
        return $api.post('/auth/resetPassword',{email})
    }
}
